/*
Template Name: QikTruck
Author: Themesdesign
File: Main scss file
*/

/*******************************
1.General
2.Helper
3.Navbar
4.Hero section
5.Testimonial
6.Pricing
7.Subscribe
8.Footer
9.Responsive
********************************/

@import "variables";

/*******************
    1.General
*******************/

body {
  font-size: $font-size-base;
  color: $body-color;
  font-family: $font-family-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark;
  font-family: $font-family-secondary;
  font-weight: $font-weight-medium;
}

a {
  text-decoration: none !important;
  outline: none;
}

p {
  line-height: 1.8;
  font-size: 12px;
}

/*******************
    2.Helper
*******************/

.card {
  border: none;
  box-shadow: $box-shadow;
  border-radius: 7px;
  margin-bottom: 30px;
}

.section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}
#root {
  padding-left: 5px;
  padding-right: 5px;
  background: #cccccc61;
}
.vertical-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.bg-overlay {
  background-color: rgba(35, 37, 47, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.small-title {
  letter-spacing: 1px;
  font-size: 12px;
}

.btn {
  font-size: 0.9rem;
  padding: 0.7rem 1.4rem;
  font-weight: $font-weight-medium;
  transition: all 0.4s;
  border-radius: 7px;
  &:hover {
    transform: translateY(-4px);
    outline: none;
    text-decoration: none;
  }
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-rounded {
  border-radius: 30px;
}

@each $name, $value in $colors {
  .bg-#{$name} {
    background-color: #{$value} !important;
  }

  .bg-soft-#{$name} {
    background-color: rgba(($value), 0.1) !important;
  }
  .text-#{$name} {
    color: #{$value} !important;
  }

  // Icons
  .icon-dual-#{$name} {
    color: $value;
    fill: rgba($value, 0.2);
  }

  .btn-#{$name} {
    color: $white;
    background: #{$value};
    border-color: #{$value};
    box-shadow: 0 8px 12px rgba($value, 0.2);

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      box-shadow: 0px 16px 31px -16px #{$value};

      background: darken($value, 4%);
      border-color: darken($value, 4%);
    }
  }

  .btn-outline-#{$name} {
    color: #{$value};
    border-color: #{$value};

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      box-shadow: 0px 16px 31px -16px #{$value};
      background: darken($value, 4%);
      border-color: darken($value, 4%);
    }
  }
}

.font-14 {
  font-size: 12px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.icons-sm {
  height: 16px;
  width: 16px;
}

.icons-md {
  height: 32px;
  width: 32px;
}

.icons-lg {
  height: 40px;
  width: 40px;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.avatar-md {
  height: 3.5rem;
  width: 3.5rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $font-weight-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/******************
    3.Navbar
*******************/

.navbar-custom {
  padding: 5px 0px;
  width: 100%;
  z-index: 999;
  border-radius: 5px;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid;
  background-color: #3c2126;
  .navbar-brand {
    font-size: 12px;
    color: white;
    img {
      width: 40px;
    }
  }
  a {
    color: #fff;
  }
  .active {
    color: #ffffff;
    font-weight: bold;
    padding: 3px 10px;
    background-color: #75816e;
    border-radius: 3px;
  }
  .navbar-right {
    top: 20px;
    position: absolute;
    right: 0;
    z-index: 1111;
    padding: 20px;
    text-align: right;
    color: wheat;
    button {
      border: solid 1px;
      border-radius: 5px;
      margin-top: 10px;
      font-size: 10px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        line-height: 26px;
        color: rgba($dark, 0.8);
        transition: all 0.4s;
        background-color: transparent !important;
        padding: 6px 10px;
        margin: 0 7px;
        &:hover,
        &:active {
          color: $primary;
        }
      }
      &.active {
        .nav-link {
          color: $primary;
        }
      }
    }
  }

  .navbar-btn {
    margin-left: 10px;
    color: $success;
    background: rgba($success, 0.2);
    border-color: transparent;
    box-shadow: none;

    &:hover {
      color: $white;
      box-shadow: 0px 16px 31px -16px $success;
      background: darken($success, 4%);
      border-color: darken($success, 4%);
    }
  }
}

.navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: $white;
}

/*--- navbar Light ---*/

.navbar-light {
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: rgba($white, 0.5);
        &:hover,
        &:active {
          color: $white;
        }
      }
      &.active {
        .nav-link {
          color: $white;
        }
      }
    }
  }
  .logo {
    .logo-light {
      display: inline-block;
    }
    .logo-dark {
      display: none;
    }
    img {
      width: 20px;
    }
  }

  .nav-sticky {
    .logo {
      .logo-dark {
        display: inline-block;
      }
      .logo-light {
        display: none;
      }
    }
  }
}

/*--- navbar sticky ---*/

.nav-sticky {
  &.navbar-custom {
    margin-top: 0px;
    background-color: black;
    padding: 5px 0px;
    box-shadow: 0 10px 33px -12px rgba(0, 0, 0, 0.1);
    .logo {
      .logo-light {
        display: none;
      }
      .logo-dark {
        display: inline-block;
      }
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: rgba($dark, 0.9);
          &:hover,
          &:active {
            color: $primary;
          }
        }
        &.active {
          .nav-link {
            color: $primary;
          }
        }
      }
    }
  }
}

/******************
    4.Hero section
*******************/

.hero-section {
  padding-top: 4%;
  overflow: hidden;
}

.home-img {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 360px;
    height: 330px;
    background-color: rgba($primary, 0.1);
    border-radius: 30% 70% 59% 41% / 30% 38% 62% 70%;
    z-index: -1;
    top: 90px;
    right: 12px;
  }
}

/*--- Hero 2 ---*/

.hero-section-2 {
  padding: 230px 0px 170px 0px;
}

.registration-form {
  .form-control {
    box-shadow: none;
    &:focus {
      border-color: $primary;
    }
  }
}

/*--- Hero 5 ---*/

.hero-section-5 {
  padding: 300px 0px 220px 0px;
}

.play-icon-circle {
  height: 50px;
  width: 50px;
  background-color: $success;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.play-iconbar {
  color: $white;
  font-size: 17px;
}

/******************
    5.Testimonial
*******************/

.testi-carousel {
  .owl-nav {
    position: absolute;
    top: -67px;
    right: 0;
    margin-top: 0px;

    button {
      outline: 0;
      border-radius: 50% !important;
      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        background: rgba($primary, 0.2);
        color: $primary;
        border-radius: 50%;
        font-size: 20px;
      }

      &:hover {
        span {
          background: $primary;
          color: $white;
        }
      }
    }
  }

  .owl-item {
    padding: 0px 10px;
  }

  .owl-dots {
    .owl-dot {
      &:focus {
        outline: 0;
      }
      span {
        background: rgba($primary, 0.2);
        border: 2px solid transparent;
      }

      &.active,
      &:hover {
        span {
          background: $white;
          border: 2px solid $primary;
        }
      }
    }
  }
}

/********** Clients ***********/

.client-images {
  img {
    max-height: 94px;
    width: auto !important;
    margin: 10px auto;
    opacity: 0.6;
    transition: all 0.5s;
    &:hover {
      opacity: 0.9;
    }
  }
}

/******************
    6.Pricing
*******************/

.pricing-nav-tabs {
  display: inline-block;
  background-color: $white;
  box-shadow: $box-shadow;
  padding: 5px;
  border-radius: 7px;
  li {
    display: inline-block;
    .nav-link {
      border-radius: 0px;
      color: $body-color;
      padding: 0.6rem 1.4rem;
      &.active {
        background-color: $primary;
      }
    }
  }
}

.pricing-plan {
  max-width: 415px;
  margin: 0px auto;
  margin-top: 30px;
}

/******************
    7.Subscribe
*******************/

.subscribe {
  .form-control {
    height: 46px;
    border-radius: 7px;
    font-size: 12px;
    box-shadow: none;
    &:focus {
      border-color: $primary;
    }
  }
}

/******************
    8.Footer
*******************/

.footer {
  background-color: $footer-bg;
  color: rgba($white, 0.5);
  padding-top: 80px;
  padding-bottom: 40px;
  a {
    color: #d0f3ca;
  }
}

.footer-list-title {
  font-size: 16px;
  color: $white;
}

.footer-list-menu {
  li {
    a {
      display: inline-block;
      font-size: 12px;
      color: rgba($white, 0.5);
      margin-bottom: 12px;
      transition: all 0.4s;
      &:hover {
        color: rgba($white, 0.9);
      }
    }
  }
}

/******************
    9.Responsive
*******************/

@media (max-width: 991px) {
  .navbar-custom {
    margin-top: 0px;
    padding: 5px 0px !important;
    box-shadow: 0 10px 33px -12px rgba(0, 0, 0, 0.1);
    background-color: black;

    &.navbar-expand-lg > .container {
      width: 90%;
    }

    .logo {
      img {
        height: 40px;
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          transition: all 0.4s;
          margin: 0px;
          padding: 6px 0px;
          color: rgba($dark, 0.9);
        }
        &.active {
          .nav-link {
            color: $primary;
          }
        }
      }
    }

    .navbar-btn {
      margin-left: 0px;
    }
  }
  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgba($dark, 0.9);
  }

  .hero-section {
    padding-top: 4%;
  }
}

@media (max-width: 767.98px) {
  .hero-title {
    font-size: 20px;
  }
}

@media (max-width: 575.98px) {
  .testi-carousel {
    .owl-nav {
      display: none;
    }
  }
}

.menu-truck {
  min-height: 150px;
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.selected-truck {
  color: green;
  font-size: 30px;
  position: absolute;
  top: 0;
}
.hide {
  display: none;
}
.grey-border-bottom {
  border-bottom: solid 1px #cccccc4a;
}
.menu-truck:hover {
  padding: 5px;
}

label {
  color: black;
  font-weight: bold;
}

.fullquote {
  text-align: right;
  float: right;
  width: 100%;
  color: black;
  font-size: 30px;
}

.error-message {
  text-align: right;
  float: right;
  width: 100%;
  color: red;
  font-size: 13px;
}

.order-received {
  font-size: 30px;
  color: green;
  margin-right: 20px;
}
.order-received-list {
  padding-top: 5px;
  margin-right: 10px;
  font-size: 25px;
}
.black-text {
  color: black;
  font-size: 12px;
}

.grey-color {
  color: gray;
}
.red-color {
  color: red;
}
.green-color {
  color: orange;
}
.footer-social-logo {
  margin-right: 12px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: black;
  background-color: #c3c7cb;
  border-color: #99a3ad;
  cursor: not-allowed;
}

.pac-container {
  background-color: #fff;
  z-index: 1050;
  position: fixed;
  display: inline-block;
  float: left;
}

.modal {
  z-index: 20;
}

.modal-backdrop {
  z-index: 10;
}

.orderdriver {
  a {
    color: #4f6367;
  }
  .in-review {
    background-color: #f38a557d;
    padding: 3px;
    border-radius: 5px;
  }
  .accepted {
    background-color: #d0f3ca;
    padding: 3px;
    border-radius: 5px;
  }
  .cancelled {
    background-color: #e5bfd1;
    padding: 3px;
    border-radius: 5px;
  }
  .declined {
    background-color: #4e4e4e;
    padding: 3px;
    color: white;
    border-radius: 5px;
  }
  .processing {
    background-color: green;
    padding: 3px;
    color: white;
    border-radius: 5px;
    margin-left: 5px;
  }
  .finished {
    background-color: #cdf0a9;
    padding: 3px;
    border-radius: 5px;
  }
}
.vehiclestatus-parent,
.driverstatus-parent {
  .in-review {
    background-color: #dec6bb7d;
    padding: 3px;
    border-radius: 5px;
  }
  .available {
    background-color: #d0f3ca;
    padding: 3px;
    border-radius: 5px;
  }
  .cancelled {
    background-color: #e5bfd1;
    padding: 3px;
    border-radius: 5px;
  }
  .declined {
    background-color: #4e4e4e;
    padding: 3px;
    color: white;
    border-radius: 5px;
  }
  .processing {
    background-color: green;
    padding: 3px;
    color: white;
    border-radius: 5px;
    margin-left: 5px;
  }
  .finished {
    background-color: #cdf0a9;
    padding: 3px;
    border-radius: 5px;
  }
}

.filteredorder {
  background-color: greenyellow;
}

.popOverAction {
  border: none;
  margin: 3px;
  padding: 10px 18px 10px 18px;
  font-size: 12px;
  border-radius: 100px;
  right: 0;
}
.popover {
  min-width: 800px;
  margin-top: 40px;
}
.tab-pane {
  button,
  input {
    border: none;
    padding: 3px 5px;
    font-size: 12px !important;
    border-radius: 2px;
    right: 0;
    margin-left: 5px;
  }
  input {
    border: solid 2px #4f6367;
  }
}
.pagination {
  float: left;
  width: 100%;
  input {
    margin-left: 0px;
    padding: 0px 5px !important;
  }
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #3c2126;
  margin-top: 0px;
  border-radius: 5px;
  padding: 5px 20px;
}

.nav-tabs-clicker {
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  color: black;
  border: none !important;
}
.nav-tabs {
  margin-bottom: 0px;
}
.pointed-at {
  cursor: pointer !important;
}

.payment-status {
  color: #4f6367;
}

.map-categories,
.map-vehicletypes {
  float: left;
  width: 100%;
  text-transform: capitalize;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  span {
    padding: 10px 10px;
  }
  .map-selector {
    float: left;
    &.selected {
      border: #4f6367 solid 1px;
    }
    img {
      height: 45px;
    }
  }
}
td {
  padding: 2px 3px !important;
}
.payment-row {
  .show-order-information {
    display: none;
  }
  div {
    float: left;
  }
  &:hover {
    background-color: rgb(240, 200, 125);
    color: rgb(37, 146, 149);
    .show-order-information {
      display: block;
    }
    .hidden-message {
      min-height: 100px;
    }
  }
  &.paid {
    background-color: #c3e9b3;
    padding: 0px 5px;
    border-radius: 3px;
  }
  &.paying {
    background-color: #b3c8e9 !important;
    border-radius: 3px;
    padding: 0px 5px;
  }
  &.preview {
    border: 2px solid #0c4fa8;
    background-color: #fada7a;
    border-radius: 3px;
    padding: 0px 5px;
  }
}
.promocode {
  font-size: 8px;
  color: rgb(48, 45, 45);
  margin-left: 10px;
  width: 90px;
  height: 20px;
  text-align: center;
  border-radius: 5px;
  margin-top: 5px;
  padding: 3px 5px;
}
.promocode-n {
  font-size: 10px;
  color: rgb(48, 45, 45);
  margin-left: 10px;
  width: 90px;
  height: 20px;
  text-align: center;
  border-radius: 5px;
  margin-top: 5px;
  padding: 3px 5px;
}
.customer-names {
  padding: 0px;
  font-size: 10px;
  color: grey;
  margin-left: 10px;
}
.driver-names {
  padding: 0px;
  color: #494646;
  font-size: 10px;
  margin-left: 10px;
  .spanner {
    float: left;
  }
  img {
    margin-left: 10px;
  }
}
.stats-holder {
  float: left;
  padding: 0px 10px;
  font-size: 10px;
  color: #aaaaaa;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  font-weight: normal;
  background-color: #ffffff !important;
  span {
    font-size: 12px;
    color: orange;
    font-weight: bold;
    margin-right: 15px;
    &.red {
      color: red;
    }
    &.green {
      color: green;
    }
  }
}
.prices-view {
  opacity: 1;
  .driver-pay,
  .commission-pay,
  .vat-pay {
    float: left;
    font-size: 10px;
  }
}
.trip-prices {
  .selling-price {
    margin-top: 1px;
  }
  margin-top: 1px;
  font-size: 12px;
  &.-modal {
    .sellingpricevalidated {
      padding: 0;
      float: left;
    }
  }
}
.validcalc {
  font-size: 12px;
  color: black;
}
.zeroval {
  font-weight: bold;
  color: #f40909;
}
.small-spinner {
  width: 20px;
  height: 20px;
}
.invalidcalc {
  font-weight: bold;
  color: red;
}
.show-hidden-message {
  &:hover {
    .hidden-message {
      display: block;
    }
  }
}
.close {
  float: right;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.cpk-calculator-info {
  float: left;
  width: 100%;
  .cpk-calculator-row {
    float: left;
    width: 40%;
  }
}
.prices-update-popup {
  position: absolute;
  z-index: 100000;
  padding: 20px;
  top: 30px;
  display: none;
  background: #4f6367;
  border-radius: 5px;
  color: #cdf0a9;
  &.paymentList {
    background-color: #ffffff;
    padding: 0px;
    margin-top: 0px;
    width: 200px;
    left: 10px;
    cursor: pointer;
    p,
    span {
      color: #0d0101 !important;
    }
    border: solid 2px #051c28;
  }
  &.driverPaymentList {
    background-color: #f8c978;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px;
    width: 250px;
    right: 10px;
    cursor: pointer;
    p,
    span {
      color: #0d0101 !important;
    }
    border: solid 2px #051c28;
  }
  &.drivercredit {
    background-color: #eee566;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    color: #051c28;
    border: solid 2px #051c28;
    label {
      color: #051c28;
      border-left: solid 2px #051c28;
      border-top: solid 2px #051c28;
      border-bottom: solid 2px #051c28;
      margin-left: 10px;
      margin-right: 0;
      padding-right: 0;
      padding-left: 20px;
    }
    input,
    textarea {
      margin-top: 5px;
      display: flex;
      width: 300px;
    }
    h5,
    h6 {
      color: #051c28;
    }
    &.hide {
    }
    &.show {
      display: block;
    }
    .white {
      color: white;
    }
    .savenew-price {
      background-color: #f8efac;
      padding: 5px 15px;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
    }
    .promocode {
      font-size: 10px;
      width: 100%;
      color: rgb(48, 45, 45);
      margin-left: 10px;
      padding: 10px 0px;
      float: left;
      background-color: none;
      background: none;
      text-align: left;
      color: white;
    }
  }
  label {
    color: #d7d1af;
    border-left: solid 2px #d7d1af;
    border-top: solid 2px #d7d1af;
    border-bottom: solid 2px #d7d1af;
    margin-left: 10px;
    margin-right: 0;
    padding-right: 0;
    padding-left: 20px;
  }
  h5,
  h6 {
    color: #cdf0a9;
  }
  &.hide {
  }
  &.show {
    display: block;
  }
  .white {
    color: white;
  }
  .savenew-price {
    background-color: #f8efac;
    padding: 5px 15px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
  }
  .promocode {
    font-size: 10px;
    width: 100%;
    color: rgb(48, 45, 45);
    margin-left: 10px;
    padding: 10px 0px;
    float: left;
    background-color: none;
    background: none;
    text-align: left;
    color: white;
  }
}
.hidden-message {
  width: 200px;
  background-color: yellow;
  border: solid 1px red;
  top: 0;
  right: 0;
  z-index: 100000;
  padding: 3px 5px;
  border-radius: 5px;
  height: 10px;
  overflow: hidden;
}
.calc-hint {
  background-color: yellow;
  height: 13px;
}
.sellingpricevalidated {
  float: left;
  padding: 0;
  margin-right: 0px;
  border-bottom: 1px solid green;
  color: #363636;
  padding-left: 5px;
  line-height: 14px;
  &.true {
    background-color: #fff;
    &.paid {
      background-color: transparent;
      color: black;
    }
  }
  &.false {
    background-color: #e5bfd1;
    color: #888585;
    &.paid {
      background-color: transparent;
    }
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100%;
  }
}

.activeOrder {
  position: absolute;
  background: wheat;
  z-index: 10000000;
  padding: 30px;
  border: 10px solid #4f6367;
  margin: 30px;
}
.activeOrderClose {
  position: absolute;
  top: 5px;
  right: 5px;
}
h6 {
  button {
    border-radius: 3px;
    margin: 5px;
    padding: 3px 20px;
    border: 2px solid #99a3ad;
    color: #4f6367;
  }
}
td {
  overflow: hidden;
  position: relative;
  a {
    color: teal;
    text-decoration: underline;
  }
}

a {
  color: teal;
  text-decoration: underline;
}

.page-item {
  .page-link {
    color: #4f6367;
    border-radius: 2px;
    padding: 0px 10px;
    font-size: 12px;
  }
  &.active {
    .page-link {
      color: #c3e9b3;
      background-color: #4f6367;
      border-color: #4f6367;
    }
  }
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
}

.move-anything {
  p {
    margin-left: 10px;
  }
  h3 {
    background-color: rgb(220, 238, 255);
    padding: 4px 20px;
    border-radius: 20px;
    margin-top: 20px;
    font-size: 20px;
    width: fit-content;
    margin-left: 0;
  }
}
.truck-hire {
  p {
    margin-left: 10px;
  }
  h3 {
    background-color: rgb(220, 255, 226);
    padding: 4px 20px;
    border-radius: 20px;
    margin-top: 20px;
    font-size: 20px;
    width: fit-content;
  }
}

.move-goods {
  p {
    margin-left: 10px;
  }
  h3 {
    background-color: rgb(255, 246, 220);
    padding: 4px 20px;
    border-radius: 20px;
    margin-top: 20px;
    font-size: 20px;
    width: fit-content;
  }
}
.other-services {
  p {
    margin-left: 10px;
  }
  h3 {
    background-color: rgb(220, 253, 255);
    padding: 4px 20px;
    border-radius: 20px;
    margin-top: 20px;
    font-size: 20px;
    width: fit-content;
  }
  a {
    color: inherit;
  }
}

.category-below {
  background-color: cornsilk;
  padding: 10px 20px;
  border-radius: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: fit-content;
}

.main-headline {
  font-size: 34px;
  margin-bottom: 20px;
}

.whats-new {
  margin-left: 5px;
  color: #00a6ff;
  border-radius: 50px;
  padding: 2px 6px;
  font-size: 10px;
  background-color: #d9ffe3;
  &.promo {
    background-color: #f8efac;
  }
}
.from-admin-website {
  float: left;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  background-color: #c3e9b3;
  border-radius: 5px;
}

.packageactions {
  button {
    margin-left: 0;
    background-color: #99a3ad;
    opacity: 0.5;
  }
  &.application {
    .application {
      background-color: rgb(128, 168, 174);
      opacity: 1;
    }
  }
  &.active {
    .active {
      background-color: #8bc34a;
      opacity: 1;
    }
  }
}
.boostexpirationdateparent {
  position: absolute;
  z-index: 1000;
  padding: 20px;
  background: #4f6367;
  border-radius: 5px;
  color: #cdf0a9;
  h4,
  h6 {
    color: #cdf0a9;
  }
}
.currentexpirationdate {
  font-size: 10px;
}
button {
  span {
    background-color: rgb(195, 239, 184);
    padding: 5px;
    border-radius: 50px;
    font-size: 10px;
  }
  &.boostexpiration {
    margin-left: 0;
    background-color: #f8efac;
  }
  &.boostexpirationdate {
    margin-left: 0;
    &.active {
      background-color: #8bc34a;
    }
  }
}
h3 {
  span {
    font-size: 13px;
    font-weight: normal;
    padding: 5px 10px;
    background-color: #f8efac;
  }
}

.startChat {
  position: absolute;
  min-width: 60%;
  height: 100%;
  width: 95%;
  background: white;
  margin: 3%;
  border: 3px solid #4f6467;
  border-radius: 5px;
  padding: 10px;
  opacity: 1;
  z-index: 10000;
  overflow-y: scroll;
  top: 0;
}
.convo-main-parent {
  .popover {
    border: 5px solid #4f6367;
    background: ivory;
  }
}
.convo-input-header {
  button {
    float: right;
    margin-top: 20px;
    border: 2px solid #4f6367;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 5px 20px;
  }
  span {
    margin-left: 10px;
    font-size: 20px;
  }
}
.convo-input-parent {
  justify-content: space-between;
  align-items: center;
  textarea {
    border-width: 3px;
    margin-top: 20px;
  }
  button {
    float: right;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid #4f6367;
    border-radius: 5px;
    padding: 5px 20px;
    &.float-left {
      float: left;
    }
  }
}
.file-preview-parent {
  float: left;
  width: 100%;
  .file-preview {
    float: left;
    border-radius: 3px;
    padding: 5px;
    margin: 4px;
    border: 1px solid #d0f3ca;
    img {
      float: left;
      width: 50px;
    }
    span {
      width: 100%;
      float: left;
    }
  }
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.jobs-list {
  float: left;
  width: 100%;
  overflow: scroll;
  position: relative;
}
.comments-list-parent {
  float: left;
  width: 100%;
  .comments-list {
    float: left;
    width: 100%;
    border: 1px dashed #ccc;
    padding: 15px 10px;
    border-radius: 5px;
    .comment-item {
      font-size: 12px;
      margin-bottom: 5px;
      float: left;
      width: 100%;
      padding-left: 0px;
      .comment-text {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        padding-left: 25px;
      }
      .comment-date {
        display: inline;
        font-size: 10px;
        color: #4f6367;
      }
      .comment-owner {
        display: inline;
        font-size: 10px;
        color: #4f6367;
        padding-left: 5px;
      }
    }
  }
}
.job-description {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px dashed #ccc;
  padding: 15px 10px;
  background: #f8efac;
}
.admin-profile-picture {
  border: 0.5px solid #5b8641;
  border-radius: 100px;
  margin-right: 3px;
  width: 10px;
  &.mini {
    width: 20px;
  }
}
.admin {
  float: left;
  font-size: 10px;
}
.profile-picture {
  float: right;
  border: 0.5px solid #5b8641;
  border-radius: 100px;
  margin-right: 3px;
  width: 30px;
  &.mini {
    width: 30px;
  }
}
.statuses-list {
  float: left;
  width: 100%;
  margin-top: 20px;
  .ant-timeline-item {
    padding-bottom: 5px;
  }
  .admin-profile-picture {
    margin-left: 20px;
  }
  .status-item {
    font-size: 10px;
    //     in-review
    // declined
    // in-review
    // accepted
    // in-progress
    // finished
    // stopped
    margin-top: 0;
    margin-bottom: 0;
    padding: 5px;
    border-radius: 3px;
    &.in-review {
      background-color: #bbd8de7d;
    }
    &.accepted {
      background-color: #f4f6c4;
    }
    &.cancelled {
      background-color: #e5bfd1;
    }
    &.declined {
      background-color: #fa8989;
      color: white;
    }
    &.processing {
      background-color: green;
      color: white;
    }
    &.in-progress {
      background-color: #f7e89e;
    }
    &.finished {
      background-color: #cdf0a9;
    }
    &.stopped {
      background-color: #e5bfd1;
    }
    b {
      font-size: 14px;
      color: #4f6367;
    }
    .status-text {
      float: left;
      width: 100%;
      margin-bottom: 5px;
      padding-left: 25px;
      font-size: 12px;
    }
    .status-date {
      display: inline;
      font-size: 10px;
      color: #4f6367;
    }
    .status-owner {
      display: inline;
      font-size: 10px;
      color: #4f6367;
      padding-left: 5px;
    }
  }
}

.admin-footer {
  background-color: #3c2126;
  padding: 20px;
  border-radius: 10px;
}

button {
  border-radius: 3px;
  margin: 3px;
  padding: 2px 5px;
  border: 1px solid #99a3ad;
  color: #4f6367;
}
input {
  border-radius: 3px;
  margin: 3px;
  padding: 2px 5px;
  border: 1px solid #99a3ad;
  color: #4f6367;
}

.map-holder {
  display: block;
}

.map-holder-parent {
  display: block;
}

.wrap-content {
  max-width: 300px;
}
.advertstatus {
  margin: 10px;
  font-size: 12px;
  font-weight: normal;
  background-color: #d9ffe3;
  border-radius: 5px;
  padding: 3px 10px;
  &.paused {
    background-color: #ffe3d9;
  }
  &.disabled {
    background-color: #c85e37;
  }
  &.processing {
    background-color: #f3cb75;
  }
}
.contractstatus {
  margin: 10px;
  font-size: 12px;
  font-weight: normal;
  background-color: #eae6ce;
  border-radius: 5px;
  padding: 3px 10px;
  &.paused {
    background-color: #ffe3d9;
  }
  &.approved {
    background-color: #8bc34a;
  }
  &.inprogress {
    background-color: #8bc34a;
  }
  &.expired {
    background-color: #c85e37;
  }
  &.stopped {
    background-color: #f19292;
  }
  &.cancelled {
    background-color: #f3cb75;
  }
  &.processing {
    background-color: #5e37c8;
    color: #ccc;
  }
  &.accepted {
    background-color: #5e37c8;
    color: #ccc;
  }
  &.completed {
    background-color: #031c0a;
    color: #ccc;
  }
}

.nearbyVehicles {
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px;
  font-size: 12px;
}

.find-a-vehicle {
  padding: 5px 10px;
  cursor: pointer;
  &.selected {
    border: #7fd05c 5px solid;
    border-radius: 5px;
    margin-bottom: 5px;
    background-color: #6f776e !important;
    margin-top: 5px;
  }
  &.active {
    border-radius: 5px;
    margin-bottom: 5px;
    background-color: #417f3a !important;
    color: white;
  }
  background-color: #e5bfd1;
  &.available {
    background-color: #7493b0;
    color: white;
  }
}
.hires-price-date {
  font-size: 12px;
  margin-right: 10px;
}
.hires-price-git {
  color: blue;
  margin: 5px;
}
.hires-price-insurance {
  color: orange;
  margin: 5px;
}
.hires-price-tracking {
  color: green;
  margin: 5px;
}
.plain-link {
  font-size: 10px;
  color: #80a8ae;
}

.pick-a-driver {
  border-radius: 50px !important;
  background-color: #ccc;
  margin: 5px !important;
}

.job-list-item {
  float: left;
  width: 100%;
  color: #3c2126;
  span {
    min-width: 20%;
    font-size: 10px;
    float: left;
  }
  .price {
    font-weight: bold;
    min-width: 35%;
    float: left;
  }
  img {
    max-width: 40px;
    float: left;
  }
  &.stopped {
    background-color: #e5bfd1;
  }
  &.finished {
    background-color: #c2e3b9;
  }
  &.accepted {
    background-color: #00a6ff9e;
  }
  &.in-progress {
    background-color: #006affbd;
  }
}
.truck-hire-item {
  float: left;
  width: 100%;
  color: #3c2126;
  .price-field {
    width: fit-content;
    display: block;
    font-size: 12px;
    float: left;
    margin-right: 10px;
  }
  img {
    max-width: 40px;
    float: left;
  }
  &.available {
    background-color: #d9ffe3;
  }
  &.in-review {
    background-color: #ffe3d9;
  }
  &.blocked {
    background-color: red;
  }
  &.processing {
    background-color: blue;
  }
  &.resting {
    background-color: yellow;
  }
}
.vehiclehire-types-list {
  position: relative;
  float: left;
  width: 95%;
  margin: 1%;
  border-radius: 10px;
  border: solid 1px #ccc;
  min-height: 550px;

  &.big {
    min-height: 800px;
  }

  .vehiclehire-types-img {
    width: 20%;
    float: left;
    min-height: 100px;
    text-align: center;
    padding-right: 10px;
    font-weight: bold;
  }
  .vehiclehire-types-type {
    width: 100%;
    max-width: 150px;
    float: left;
  }
  .vehiclehire-types-info {
    float: left;
    width: 40%;
    border-left: solid 1px #ccc;
    .vehiclehire-types-stat {
      float: left;
      border-bottom: solid 1px #ccc;
      width: 100%;
      padding: 3px 5px;
      min-height: 30px;
      b {
        font-size: 12px;
        color: grey;
        margin-right: 20px;
        min-width: 60px;
        float: left;
      }
      &.finished {
        background-color: #d9ffe3;
      }
      &.stopped {
        background-color: #ffe3d9;
      }
      &.accepted {
        background-color: lightblue;
      }
      &.processing {
        background-color: blue;
      }
      &.resting {
        background-color: yellow;
      }
    }
  }
  .vehiclehire-types-graph {
    float: left;
    width: 100%;
  }
}
.section-list {
  padding: 10px 20px;
}
.toggler {
  position: absolute;
  padding: 20px 30px;
  z-index: 10000000;
  background-color: wheat;
  border-radius: 5px;
  margin: 10px;
  span {
    margin: 5px;
    border: solid 1px #ccc;
    padding: 5px 10px;
  }
  .selectedv {
    border: 2px green solid;
    border-radius: 5px;
  }
}

.contract-header {
  span {
    margin-right: 10px;
  }
}

.contract-header-info {
  display: block;
  span {
    margin-right: 10px;
    display: block;
  }
}
.contract-stats {
  font-size: 12px;
  float: left;
  width: 100%;
  span {
    float: left;
    border-right: 2px solid #ccc;
    margin-left: 10px;
    padding-right: 10px;
  }
}
.vehicle-profileinfo {
  float: left;
  min-width: 30%;
  padding: 3px 5px;
  font-size: 12px;
  border: solid 1px #ccc;
}
.contract-status-parent {
  button {
    padding: 3px 5px;
    font-size: 12px;
    border-radius: 2px;
  }
}

.file-preview-hires {
  img {
    width: 50px;
  }
}
.hires-prices {
  float: left;
  width: 100%;
  border-bottom: 1px solid rgb(244, 244, 244);
  min-height: 200px;
}

.hire-price-value {
  border-top: 1px solid #ccc;
  margin-right: 10px;
  margin-left: 10px;
}

.vehicle-names {
  font-size: 12px;
}

.contract-trip-booker {
  border-left: 1px solid #ccc;
  padding-left: 10px;
}

.findavehicle {
  display: block;
}

.customerslist {
  float: left;
  width: 38%;
  border-right: none !important;
}

.contractslist {
  float: left;
  padding: 12px;
  border: solid 2px #8bc34a;
  width: 61%;
  margin-top: 36px;
}

.cst {
  border-radius: 5px !important;
  border-bottom: 4px solid #8bc34a;
  border-left: 4px solid #8bc34a;
  border-top: 4px solid #8bc34a;
}
.contractsitem {
  border-bottom: 1px solid #8bc34a;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.documents {
  a {
    float: left;
    width: 100%;
    max-width: 200px;
    padding: 3px;
    border: solid 1px #ccc;
    overflow: hidden;
    min-height: 60px;
    font-size: 10px;
  }
}

.driverresponse {
  padding: 3px 5px;
  border: 1px solid #ccc;
  font-size: 10px;
  border-radius: 5px;
  &.accepted {
    background-color: green;
    color: #c2e3b9;
  }
  &.cancelled {
    background-color: red;
    color: #c2e3b9;
  }
  &.declined {
    background-color: red;
    color: #c2e3b9;
  }
}

.vhtype {
  width: fit-content;
  display: inline-block;
  border: solid 1px grey;
  margin-right: 5px;
  padding: 3px 5px;
  margin-bottom: 3px;
  border-radius: 5px;
  font-size: 10px;
  cursor: pointer;
  &.selected {
    border: 2px solid green;
  }
}

.truck-hire-field {
  float: left;
  margin-right: 20px;
  margin-top: 12px;
  min-width: 100px;
  padding: 10px;
  &.description {
    width: 30%;
    border-right: 2px solid #ccc;
    border-left: 2px solid #ccc;
  }
}

.small-text {
  font-size: 10px;
  position: absolute;
  top: -5px;
  color: #5e37c8;
}

.lowercase {
  text-transform: lowercase;
}

.vehicle-finder {
  button {
    margin-right: 10px;
    margin-left: 10px;
    border: solid 2px #ccc;
    border-radius: 3px;
    &.selected {
      background-color: #bcf2cc;
    }
  }
}

.vehicles-picker-list {
  max-height: 700px;
  overflow: scroll;
}

.promoted-job {
  background-color: rgb(197, 197, 98);
  padding: 3px 20px;
}

.confirm-driver-payment {
  position: absolute;
  text-align: left;
  padding: 0;
  margin: 0px;
  left: 10px;
}

.reset-btn {
  padding: 0px 5px;
  text-transform: capitalize;
  &.active {
    background-color: #f7bf6b;
    color: #222020;
    padding: 0px 45px;
    letter-spacing: 3px;
    font-weight: bold;
  }
}

.driver-pay {
  color: #89795d;
  padding-left: 5px;
  padding-right: 5px;
  border-right: 1px solid #ccc;
}
.commission-pay {
  padding-right: 5px;
  color: #89795d;
  border-right: 1px solid #ccc;
  margin-left: 5px;
}
.vat-pay {
  margin-left: 5px;
  color: #89795d;
}

.collectiondate {
  min-width: 300px;
}
.date-grouping {
  font-size: 10px;
  padding: 0px 5px;
  border-radius: 3px;
  background-color: #aeff00d2;
  width: fit-content;
  margin-right: 15px;
  &.week_start,
  &.week_end {
    background-color: #bbb8b8bd;
    padding: 0px 5px;
  }
}
.red {
  color: #c26666;
  margin-right: 10px;
}
.insights {
  font-size: 10px;
  .bold {
    font-weight: bold;
  }

  i {
    margin-right: 5px;
  }
}

.comments {
  background-color: #bed6e7;
  padding: 2px 5px;
}

.statuses {
  background-color: #d7d1af;
  padding: 2px 5px;
  &.true {
    background-color: #cdf0a9;
  }
}
.status-selector {
  padding: 0px;
}

.orange {
  color: #ff9800;
}
.blue {
  color: #2196f3;
}
.green {
  color: #4caf50;
}

.places-driver-location {
  min-width: 350px;
  max-width: 350px;
}

.btn {
  padding: 3px 10px;
  border-radius: 3px;
  box-shadow: 0 3px 3px 0 #ddd, 0 0 2px 0 #ddd;
}

.allpaid {
  background-color: #c3e9b3;
  color: #2a2e30;
  border-radius: 3px;
  padding: 2px 10px;
  font-size: 12px;
  margin: 0;
}

.filtercenter {
  float: left;
  height: 30px;
  .filterpromocodebtn {
    overflow: hidden;
    padding: 1px 5px;
    font-size: 12px;
    text-transform: uppercase;
    background-color: orange;
    border: solid 1px #ccc;
    color: black;
    margin: 1;
    margin-top: 3px;
    padding: 3px 25px;
    cursor: pointer !important;
    &.selected {
      background-color: #93d1b4;
      color: #010f08;
    }
    &:hover {
      background-color: #ace8f8;
      cursor: pointer;
      color: black;
    }
  }
  .filterwhopaidwhodebtnparent {
    button {
      overflow: hidden;
      padding: 1px 5px;
      font-size: 12px;
      text-transform: uppercase;
      background-color: rgb(250, 250, 250);
      border: none;
      color: black;
      margin: 1;
      margin-top: 3px;
      padding: 3px 15px;
      cursor: pointer !important;
    }
    .filterwhopaidwhodebtn {
      overflow: hidden;
      padding: 1px 5px;
      font-size: 10px;
      text-transform: uppercase;
      background-color: rgb(250, 250, 250);
      border: solid 1px #ccc;
      color: black;
      margin: 1;
      margin-top: 3px;
      padding: 3px 15px;
      &.customerbank-to-qiktruck {
        background-color: #00a6ff !important;
      }
      &.customerpayfast-to-qiktruck {
        background-color: #00ccff !important;
      }
      &.customerbank-to-driver {
        background-color: #ff4d00df;
        border-left: 5px solid orange;
        border-right: 5px solid orange;
      }
      &.customercash-to-driver {
        background-color: #ff0000 !important;
      }
      cursor: pointer !important;
      &.selected {
        background-color: #93d1b4;
        color: #010f08;
      }
      &:hover {
        background-color: #ace8f8;
        cursor: pointer;
        color: black;
      }
    }
  }
  .filterpromocode {
    overflow: hidden;
    padding: 1px 5px;
    font-size: 12px;
    text-transform: uppercase;

    margin: 1;
    margin-top: 3px;
    padding: 3px 5px;
    cursor: pointer !important;
    &.selected {
      background-color: #93d1b4;
      color: #010f08;
    }
    &:hover {
      background-color: #ace8f8;
      cursor: pointer;
      color: black;
    }
  }
  .spinner-border {
    width: 10px;
    height: 10px;
    float: right;
  }
}

.modal-dialog {
  width: 70%;
  float: right;
}

.confirm-driver-payment-parent {
  float: left;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: #f8efac;
  border: 2px solid #051c28;
  margin-bottom: 20px;
  margin-top: 20px;
}

.small-spinner {
  width: 10px;
  height: 10px;
  margin: 5px;
}

.driver-prices {
  float: left;
  width: 100%;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 30px;
  .stats-holder {
    float: left;
    padding: 5px 10px;
    font-size: 12px;
    color: #494343;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin: 2px;
    margin-bottom: 0px;
    min-width: 150px;
    span {
      font-size: 15px;
      margin-right: 10px;
      color: orange;
      &.red {
        color: red;
      }
      &.green {
        color: green;
      }
    }
  }
}

.main-filter {
  float: left;
  padding: 0px;
  width: 100%;
}

.pagination-text {
  font-size: 12px !important;
  color: #4f6367;
  margin-left: 10px;
}

.ant-drawer-body {
  padding: 5px !important;
}
.file-upload-form {
  padding-right: 10px;
  position: relative;
  float: left;
  input,
  textarea {
    width: 100%;
    margin: 0;
    margin-bottom: 5px;
  }
}

.files-uploader-parent {
  background-color: #ebebeb;
  float: left;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.trip-id {
  // style={{ marginRight: "10px", fontSize: "10px", float: "left" }}>
  margin-right: 10px;
  font-size: 10px;
  float: left;
}

.order-delivery-statuses {
  right: 0px;
  top: 0px;
  margin: 5px;
  font-size: 12px;
  .order-delivery-status {
    border: solid 1px #ccc;
    padding: 2px 10px;
    border-radius: 3px;
    float: left;
    margin: 5px;
    &:hover {
      background-color: #f8efac;
      cursor: pointer;
    }
  }
  input {
    margin: 5px;
    border-radius: 3px;
  }
}
.paymentreceiver {
  padding: 0px;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 9px;
  color: white;

  &.null {
    background-color: #fffb00;
    color: black;
  }
}

.customerbank-to-qiktruck {
  background-color: #00a6ff !important;
}
.customerpayfast-to-qiktruck {
  background-color: #00ccff !important;
}
.customerbank-to-driver,
.customercash-to-driver {
  background-color: #ff4d00b6;
  border-left: 5px solid orange;
  border-right: 5px solid orange;
  color: black;
  color: rgb(34, 33, 33);
}

.orderid-review-transactions {
  background-color: #f8efac;
  padding: 0px 15px;
  display: inline;
}

.driver-profile-payment {
  background-color: #acdcf8;
  padding: 5px 10px;
  border-radius: 5px;
}

img.admin-profile-picture {
  border: 0.5px solid #5b8641;
  border-radius: 100px;
  margin-right: 3px;
  width: 20px;
  &.mini {
    width: 20px;
  }
}

.vehiclesearch {
  width: 200px;
  margin: 5px;
  float: left;
}
// .ant-pagination {
//   padding: 0 !important;
//   margin: 3px !important;
//   .ant-pagination-item,
//   .ant-pagination-item-link,
//   .ant-pagination-prev,
//   .ant-pagination-next {
//     width: 20px;
//     height: 20px;
//     line-height: 15px;
//     padding: 0 !important;
//     margin: 3px !important;
//   }
// }
.main-filter{
  float: left;
  padding: 0px;
  width: 100%;
}
.main-select{
  max-width: 200px;
  float: left;
  margin: 5px;
}

.white-text{
  color: white;
}